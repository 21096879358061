import logoLink from '../resources/nobrand.svg';

export const cssVariables = {
    primary_light: 'rgb(153, 203, 195)',
    primary: 'rgb(0, 168, 165)',
    text_on_primary: 'white',
    primary_dark: 'rgb(0, 108, 105)',

    secondary: '#D25425',
    secondary_light: '#FBEEE9',
    text_on_secondary: 'white',
    secondary_transparent: '#FFFBFA',

    font: 'Helvetica, Arial, sans-serif',
    link_color: 'rgb(0, 131, 177)',
    font_color: '#000',

    border_radius: '0px',
    border_width: '1px',
    border_style: 'solid',

    active: 'rgb(183, 233, 225)',
    inactive: '#ECB393',
};

export const logo = logoLink;
